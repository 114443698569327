import React, { useContext, useEffect, useState } from 'react';
import { auth, db } from '../../firebase/firebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { ref, onValue } from 'firebase/database';
import API from '../API';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
};

export function AuthProvider({ children }) {
    const [userObj , setUserObj] = useState(null);
    const [authUser, setAuthUser] = useState(null);
    const [vcardObj, setVcardObj] = useState(null);
    const [loading, setLoading] = useState(true);
    const [route, setRoute] = useState(null);
    const [messageObj, setMessageObj] = useState(null);

    const signup = async (email, password) => {
        return await createUserWithEmailAndPassword(auth, email, password);
    };

    const login = async (email, password) => {
        return await signInWithEmailAndPassword(auth, email, password);
    };

    const logout = async () => {
        return await auth.signOut();
    };

    const resetPassword = async (email) => {
        return await auth.sendPasswordResetEmail(email);
    };

    const updateEmail = async (email) => {
        return await auth.currentUser.updateEmail(email);
    };

    const updatePassword = async (password) => {
        return await auth.currentUser.updatePassword(password);
    };
    
    const updateLoginMetadata = async () => {
        const update = {
            date: Date.now(),
            device: window.navigator.userAgent
        };
        
        await API.updateUserLoginAnalytics(update);
    };

    const getUpdatedUserVcard = async () => {
        return await API.getUserVcard().then(res => setVcardObj(res.data));
    };

    const getUserData = async (uid) => {
        if(vcardObj === null){
            await API.getUserVcard().then(res => {
                setVcardObj(res.data);
            });
        };

        if (userObj === null) {
            const query = ref(db, `users/${uid}`);
            onValue(query, (snapshot) => {
                if (snapshot.exists()) {
                    setUserObj(snapshot.val());
                    setRoute(snapshot.val().enterprise ? `${snapshot.val().enterprise}/${snapshot.val().username}` : `${snapshot.val().username}`);
                };
            });
        };

        if (messageObj === null) {
            const query = ref(db, `message/${uid}`);
            onValue(query, (snapshot) => {
                if (snapshot.exists()) {
                    setMessageObj(snapshot.val());
                };
            });
        };
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
                setUserObj(null);
                setVcardObj(null);
            };
            setLoading(false);
        });
        
        return unsubscribe;
    });

    useEffect(() => {
        if (auth?.currentUser) {
            getUserData(authUser.uid);
            updateLoginMetadata();
        };
    },[authUser]);

    const value = {
        userObj,
        vcardObj,
        messageObj,
        authUser,
        getUserData,
        login,
        signup,
        logout,
        resetPassword,
        getUpdatedUserVcard,
        updateEmail,
        updatePassword,
        updateLoginMetadata,
        route
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
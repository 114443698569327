import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import TagManager from 'react-gtm-module'
// import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
  gtmId: 'GTM-MD6FVX76'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <App />,
  document.getElementById('root')
);


// reportWebVitals();

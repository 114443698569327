import axios from 'axios';
import { storage, auth, db } from '../firebase/firebaseConfig';
import { ref as dbRef, query, equalTo, orderByChild, get, update, startAfter, limitToFirst } from 'firebase/database';
import { ref, uploadBytes, deleteObject, getDownloadURL  } from 'firebase/storage';

export const authToken = () => auth.currentUser.getIdToken(true);

const handleFileExists = async (oldStorageRef) => {
    try {
        await getDownloadURL(oldStorageRef);
        return true;
    } catch (err) {
        return false;
    };
};

const API = {
    // -----------------------------------------------------
    // -----------------START USERS ROUTES------------------
    // -----------------------------------------------------
    
    // Save user object to users document
    createNewUser: async (data) => {
        return await axios.put(`/api/users/`, data);
    },

    // Get card by route
    getUserByUsername: async ({ username, enterprise }) => {
        return await axios.get(`/api/admin/username/${username}/${enterprise}`);
    },

    // Get enterprise user card by route
    getEnterpriseUserByUsername: async (username, enterprise) => {
        const userRef = query(dbRef(db, 'users/'), orderByChild('username'), equalTo(username));
        const obj = {};

        await get(userRef).then((snapshot) => {
            const user = Object.keys(snapshot.val()).filter(name => !name.includes(enterprise) && snapshot.val()[name].enterprise === enterprise).map(obj => obj)[0];
            obj.user = snapshot.val()[user];
            obj.uid = user;
        });

        return obj;
    },

    // Update user page type or redirect url
    updateUserObject: async (data) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.put(`/api/users/update`, data, config);
        });
    },

    // Get DBC skills
    getSkillsByUID: async (uid) => {
        return await axios.get(`/api/admin/skills/${uid}`);
    },

    // -----------------------------------------------------
    // -----------------START VCARD ROUTES------------------
    // -----------------------------------------------------

    // Returns user VCard object
    getUserVcard: async () => {
        return await authToken().then(async (token) => {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };

            return await axios.get(`/api/vcard/`, config);
        });
    },

    // Returns user VCard Object, searching by UID
    getVcardByUID: async (uid) => {
        const vcardRef = dbRef(db, `vcard/${uid}`);
        const vcardSnap = await get(vcardRef);
        return vcardSnap.val();
    },

    getEntManagedVcardByUid: async (obj) => {
        return await axios.post(`/api/admin/entVcard`, obj);
    },

    // Updates VCard information
    updateVcardObject: async (data) => {
        await authToken().then(async (token) => {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };

            return await axios.put(`/api/vcard/update`, data, config);
        });
    },

    // -----------------------------------------------------
    // ----------------START CONTACTS ROUTES----------------
    // -----------------------------------------------------

    // Add new contact
    addContact: async (data) => {
        await authToken().then(async (token) => {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };

            const contactsRef = dbRef(db, `contacts/${Object.keys(data.sender)[0]}/${auth.currentUser.uid}`);
            await get(contactsRef).then(async snapshot => {
                if (snapshot.exists()) {
                    if (snapshot.val().connected_date) {
                        data.sender[Object.keys(data.sender)[0]].connected_date = snapshot.val()?.connected_date;
                        const userContactRef = dbRef(db, `contacts/${auth.currentUser.uid}`);
                        await update(userContactRef, data.sender);
                        return;
                    } else {
                        data.sender[Object.keys(data.sender)[0]].connected_date = data.sender[Object.keys(data.sender)[0]].added_date;
                        const userContactRef = dbRef(db, `contacts/${auth.currentUser.uid}`);
                        await update(userContactRef, data.sender);
                        return;
                    };
                } else {
                    const userContactRef = dbRef(db, `contacts/${auth.currentUser.uid}`);
                    await update(userContactRef, data.sender);

                    // console.log(data.receiver);
                    const senderContactRef = dbRef(db, `contacts/${Object.keys(data.sender)[0]}`);
                    await update(senderContactRef, data.receiver).catch(err => console.log(err));

                    const messageData = {
                        uid: Object.keys(data.sender)[0],
                        message: data.message
                    };

                    return await axios.put('/api/contacts/', messageData, config);
                };
            });
        });
    },

    // Get users contacts
    getContacts: async (offset) => {
        const contactsRef = dbRef(db, `contacts/${auth.currentUser.uid}`);
        const q = query(contactsRef, orderByChild('name_last'), startAfter(offset), limitToFirst(10));
        return await get(q).then(snapshot => snapshot.val());
    },

    checkContactStatus: async (cardUID) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };

            return await axios.get(`/api/contacts/connected/${cardUID}`, config);
        });
    },

    // Adds contact in return
    updateContactStatus: async (data) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };

            return await axios.put(`/api/contacts/update/`, data, config);
        });
    },

    // Deletes contact for both accounts
    deleteContact: async (deleteID) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };

            await axios.delete(`/api/contacts/delete/${deleteID}`, config);
        });
    },

    // -----------------------------------------------------
    // ----------------START STORAGE ROUTES-----------------
    // -----------------------------------------------------

    uploadResume: async (uid, file, fileName) => {
        const storageRef = ref(storage, `${uid}/${fileName}`)
        return await uploadBytes(storageRef, file);
    },

    updateBackground: async (uid, file, fileName, oldFile) => {
        if (oldFile && oldFile.includes(uid)) {
            const oldStorageRef = ref(storage, `${oldFile}`);
            const exists = await handleFileExists(oldStorageRef);

            if (exists) {
                try {
                    await deleteObject(oldStorageRef);
                } catch (err) {
                    console.log('Error deleting old background image', err);
                };
            };
        };

        const storageRef = ref(storage, `${uid}/${fileName}`);
        return await uploadBytes(storageRef, file).then(() => true).catch(() => false);
    },

    deleteBackground: async (path) => {
        const storageRef = ref(storage, path);
        const exists = handleFileExists(storageRef);
        
        try {
            if (exists) await deleteObject(storageRef);
            return;
        } catch (err) {
            console.log(err);
            return
        };
    },

    // -----------------------------------------------------
    // ---------------START MESSAGING ROUTES----------------
    // -----------------------------------------------------
    
    // User's received messages
    sendUserMessage: async (message) => {
        return await axios.post(`/api/message/send`, message);
    },

    // User's reply to a received message
    replyToMessage: async (message) => {
        return await axios.post(`/api/message/reply`, message);
    },

    // Get user messages
    getUserMessages: async () => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.get(`/api/message/`, config);
        });
    },

    // Stores message in "api/message/:uid"
    postMessageToDB: async (uid, data) => {
        return await axios.post(`/api/message/${uid}`, data);
    },

    postReplyToDB: async (data) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };
            
            return await axios.post(`/api/message/reply/save`, data, config);
        });
    },

    // Update message read in "api/message/:uid/:randkey"
    updateMessageRead: async (randkey, data) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };
            
            return await axios.put(`/api/message/read/${randkey}`, data, config);
        });
    },

    // Deletes user message
    updateDeletedMessage: async (msgID, date) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };
            
            return await axios.put(`/api/message/update/delete/${msgID}`, date, config);
        });
    },

    deleteMessage: async (arr) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };
            
            return await axios.put(`/api/message/delete`, arr, config);
        });
    },

    // -----------------------------------------------------
    // -----------------START SRIPE ROUTES------------------
    // -----------------------------------------------------

    // Creates checkout session for new/returning customers
    createCheckoutSession: async (data) => {
        return await axios({
            method: 'post', 
            url: `${process.env.REACT_APP_STRIPE_API_URL}/api/v1/checkout`,
            data: data
        });
    },

    // Creates portal session for existing customers
    createPortalSession: async (data) => {
        return await axios({
            method: 'post',
            url: `${process.env.REACT_APP_STRIPE_API_URL}/api/v1/portal`,
            data: data
        });
    },

    getCheckoutSession: async (data) => {
        return await axios({
            method: 'post',
            url: `${process.env.REACT_APP_STRIPE_API_URL}/api/v1/session`,
            data: data
        })
    },

    // -----------------------------------------------------
    // ---------------START ANALYTICS ROUTES----------------
    // -----------------------------------------------------

    // Creates new user analytics
    createNewAnalytics: async (data) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.post(`/api/stats/new`, data, config);
        });
    },

    // Gets user analytics by UID
    getUserAnalytics: async () => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.get(`/api/stats/`, config);
        });
    },

    // Update download count
    updateDownloadCount: async (data) => {
        return await axios.put(`/api/stats/downloads`, data);
    },

    // Updates user analytics
    updateUserLoginAnalytics: async (data) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.put(`/api/stats/`, data, config);
        });
    },

    // Updates button analytics
    updateButtonAnalytics: async (data) => {
        return await axios.put(`/api/stats/button_click/`, data);
    },

    // Updates card views
    updateCardViews: async (data) => {
        return await axios.put(`/api/stats/card_view`, data);
    },

    // -----------------------------------------------------
    // -----------------START EVENTS ROUTES-----------------
    // -----------------------------------------------------
    
    // Checks events badge
    checkEventBadge: async (eventId, badgeId) => {
        return await axios.get(`/api/events/${eventId}/${badgeId}`);
    },

    checkVendorStatus: async (event_id) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.get(`/api/events/vendor/${event_id}`, config);
        });
    },

    // gets event obj
    getEvent: async (eventId) => {
        return await axios.get(`/api/events/${eventId}`);
    },

    getEventTheme: async (eventId) => {
        return await axios.get(`/api/events/theme/${eventId}`);
    },

    getUserEvents: async () => {
        const promiseArr = [];

        const userEventsRef = dbRef(db, `user_events/${auth.currentUser.uid}`);
        const userEvents = await get(userEventsRef).then((snapshot) => {
            if (snapshot.exists()) {
                return Object.values(snapshot.val());
            } else {
                return [];
            };
        }, () => false);

        if (userEvents && userEvents?.length > 0) {
            userEvents.forEach((obj) => {
                promiseArr.push(new Promise(async (resolve) => {
                    const eventRef = dbRef(db, `events/${obj.event_id}/end_date`);
                    const event = await get(eventRef).then((snapshot) => snapshot.val(), (err) => false);
                    resolve({ ...obj, end_date: event });
                }));
            });

            return await Promise.all(promiseArr).then((res) => res, () => []);
        } else {
            return [];
        };
    },

    getEventVendorId: async (eventId, uid) => {
        return await axios.get(`/api/admin/events/${eventId}/${uid}`);
    },

    getEventContactVcardByUID: async (arr) => {
        const promiseArr = [];
        arr.forEach((uid) => {
            promiseArr.push(new Promise(async (resolve) => {
                const userRef = dbRef(db, `users/${uid}/enterprise`);
                const vcardRef = dbRef(db, `vcard/${uid}`);
                const arrKeys = ['name_first', 'name_last', 'organization_name', 'username', 'profile_pic', 'messaging'];
                
                const vcardData = {}
                const vcardSnap = await get(vcardRef);
                const userSnap = await get(userRef);
                
                Object.keys(vcardSnap.val()).forEach((key) => { arrKeys.includes(key) && (vcardData[key] = vcardSnap.val()[key]) });

                resolve({enterprise: userSnap.val(), ...vcardData, uid: uid, route: userSnap.val() ? `/${userSnap.val()}/${vcardData.username}` : `/${vcardData.username}` });
            }));
        });

        return await Promise.allSettled(promiseArr).then(res => res.map(obj => obj.value));
    },

    getEventPointTotal: async (eventId) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };
            
            return await axios.get(`/api/events/points/${eventId}`, config);
        });
    },

    getUserEventAnalytics: async (eventId) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.get(`/api/events/stats/${eventId}`, config);
        });
    },

    getEventVendors: async (eventId, vendorId) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };
            
            return await axios.get(`/api/events/vendors/${eventId}/${vendorId}`, config);
        });
    },

    getEventVendorObject: async (eventId, vendorId) => {
        return await axios.get(`/api/events/vendorObj/${eventId}/${vendorId}`);
    },

    getEventUserDetails: async (eventId, uid) => {
        return await axios.get(`/api/events/user/${eventId}/${uid}`);
    },

    getPromotedEvents: async () => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.get(`/api/events/promoted`, config);
        })
    },

    getAllVendorConnections: async (arr) => {
        const promiseArr = [];
        arr.forEach((uid) => {
            promiseArr.push(new Promise(async (resolve) => {
                const userEmailRef = dbRef(db, `users/${uid}/email`);
                const vcardRef = dbRef(db, `vcard/${uid}`);
                const arrKeys = ['name_first', 'name_last', 'organization_name', 'job_title'];

                const emailSnap = await get(userEmailRef);
                const vcardSnap = await get(vcardRef);
                
                const data = {
                    email: emailSnap.val(),
                };

                if (vcardSnap.exists()) {
                    Object.keys(vcardSnap.val()).forEach((key) => { arrKeys.includes(key) && (data[key] = vcardSnap.val()[key]) });
                };
                resolve(data);
            }));
        });

        return await Promise.allSettled(promiseArr).then(res => res.map(obj => obj.value));
    },

    // update event obj
    updateEventBadge: async (data) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.put(`/api/events/update`, data, config);
        });
    },

    revokeEventBadgeStatus: async (data) => {
        return await authToken().then(async (token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}`}
            };

            return await axios.put(`/api/events/revoke`, data, config);
        });
    },

    addNewVendorGroup: async (data) => {
        return await axios.put(`/api/events/addVendor`, data);
    },

    updateEventPointTotal: async (data) => {
        return await axios.put(`/api/events/points`, data);
    },

    updateVendorStatus: async (data) => {
        return await axios.put(`/api/events/vendor/${data.event_id}`, data);
    },

    updateVendorsList: async (data) => {
        return await axios.put(`/api/events/vendors/list`, data);
    },

    updateEventHostButtonClickAnalytics: async (data) => {
        return await axios.put(`/api/events/stats/${data.event_id}`, data);
    },

    updateEventAnalytics: async (data) => {
        return await axios.put(`/api/stats/events`, data);
    },

    // -----------------------------------------------------
    // -----------------START REFERER ROUTES-----------------
    // -----------------------------------------------------
    
    getNetworkObject: async (network) => {
        return await axios.get(`/api/network/${network}`);
    },
};

export default API;